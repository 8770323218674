import React, { Fragment, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import language from 'i18next';
import request from '../../api/request';
import { USER_LANGUAGE_CHANGE } from '../../api/ApiConstants';

const LanguageSwitch = () => {
	const [currentLang, setCurrentLang] = useState('AR');

	LanguageSwitch.changeLanguage = (lang) => {
		console.log('lang', lang);
		if (currentLang !== lang) {
			if (localStorage.getItem('token')) {
				request(USER_LANGUAGE_CHANGE.replace(':id', lang === 'EN' ? 1 : 2), { method: 'POST', payload: {} }).then(
					() => {
						language.changeLanguage(lang);
						setCurrentLang(lang);
						window.location.reload();
					}
				);
			} else {
				language.changeLanguage(lang);
				setCurrentLang(lang);
				window.location.reload();
			}
		}
	};

	LanguageSwitch.changeToEnglish = () => LanguageSwitch.changeLanguage('EN');
	LanguageSwitch.changeToArabic = () => LanguageSwitch.changeLanguage('AR');

	useEffect(() => {
		const temp = language.resolvedLanguage?.toUpperCase() || 'AR';
		setCurrentLang(temp);
	}, []);

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle id='dropdown-basic'>{currentLang}</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						onClick={LanguageSwitch.changeToEnglish}
						className={currentLang === 'EN' ? 'actives' : ''}>
						EN
					</Dropdown.Item>
					<Dropdown.Item
						onClick={LanguageSwitch.changeToArabic}
						className={currentLang === 'AR' ? 'actives' : ''}>
						AR
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

export default LanguageSwitch;
