import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const fallbackLng = 'ar';
const availableLanguages = ['en', 'ar'];

i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		load: 'languageOnly',
		fallbackLng,
		debug: true,
		cleanCode: true,
		preload: false,
		supportedLngs: availableLanguages,
		nonExplicitSupportedLngs: true,
		whitelist: availableLanguages,
		detection: {
			order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			lookupFromPathIndex: 0,
			lookupFromSubdomainIndex: 0,
			checkWhitelist: true
		},
		interpolation: {
			escapeValue: false
		}
	});

// export default i18next;
